import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

export const loginRoute = '/session';

const loginRoutes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full'},
  { path: 'login', component: LoginComponent},
  { path: 'reset-password/:token', component: ResetPasswordComponent },
];

export const SessionRoutingModule: ModuleWithProviders<RouterModule> = RouterModule.forChild(loginRoutes);
