import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IApiLoginCredentials } from '../../../common/interfaces/login-credentials.api.interface';
import { Observable } from 'rxjs';
import { map, tap, } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from '../../../environments/environment';
import { SessionService } from './session.service';
import { IApiUser } from '../../../common/interfaces/user.api.interface';

@Injectable()
export class SessionApiService {

  private apiUrl = `${environment.api}/session`;

  constructor(
    private httpClient: HttpClient,
    private sessionService: SessionService
  ) { }

  login(credentials: IApiLoginCredentials): Observable<User> {
    return this.httpClient.post<any>(`${this.apiUrl}/signIn`, credentials)
      .pipe(map<any, IApiUser>(session => session.User),
        map(apiUser => new User(apiUser)),
        tap(user => this.sessionService.signIn(user)));
  }

  register(user: IApiUser): Observable<User> {
    return this.httpClient.post<any>(`${this.apiUrl}/signUp`, user)
      .pipe(map(session => session.User), map(apiUser => new User(apiUser)));
  }

  recovery(email: string): Observable<any> {
    return this.httpClient.post<any>(`${this.apiUrl}/recoveryRequest`, { email: email });
  }

  getSessionByRecoveryToken(recoveryToken: string): Observable<User> {
    return this.httpClient.get(`${this.apiUrl}/checkRecoveryToken/${recoveryToken}`)
      .pipe(map<any, IApiUser>(session => session.User),
        map<IApiUser, User>((apiUser: IApiUser) => new User(apiUser)),
        tap<User>(u => this.sessionService.signIn(u)));
  }

  logout(): Observable<any> {
    this.sessionService.logout();
    localStorage.clear();
    return this.httpClient.delete<any>(`${this.apiUrl}/logout`, {});
  }
}
