import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import isArray from 'lodash-es/isArray';
import _map from 'lodash-es/map';
import { Observable } from 'rxjs';
import { Role } from '../../shared/models/role';
import { IUserRole } from '../../../common/interfaces/user.api.interface';

@Injectable()
export class RoleApiService {

  private apiUrl = `${environment.api}/role`;

  constructor(
    private httpClient: HttpClient
  ) { }

  getRole(id?: number): Observable<Role[]> {
    return this.httpClient.get<IUserRole[] | IUserRole>(`${this.apiUrl}/${!id ? '' : id}`)
      .pipe(map(role => isArray(role) ? _map(role, u => new Role(u)) : [new Role(role)]));
  }
}
