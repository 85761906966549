import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { SessionApiService } from '../../shared/session/session.api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SnackBarService } from '../../shared/snackbar/snackbar.service';
import { SnackBarRole } from '../../shared/snackbar/snackbar-role.enum';
import { Language } from 'angular-l10n';
import { UserApiService } from '../../shared/services/user.api.service';
import { ConfirmPasswordValidator } from '../../shared/confirm-password.validator';
import { User } from '../../shared/models/user';
import { CommonErrors } from '../../../common/errors';

@Component({
  selector: 'wb-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  @Language()
  public lang: string;

  public resetPasswordForm: FormGroup;
  public disabled: boolean;
  private token: string;

  public submitted = false;

  private user: User;

  constructor( public sessionApiService: SessionApiService,
    public router: Router,
    public route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private userService: UserApiService,
    private snackBar: SnackBarService) {
      this.resetPasswordForm = this.formBuilder.group({
        id: ['', Validators.required],
        password: ['', [Validators.required, Validators.minLength(8)]],
        passwordConfirm: ['']
      }, {validator: ConfirmPasswordValidator.MatchPassword});
      this.resetPasswordForm.controls['passwordConfirm'].markAsDirty();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.token = params['token'] || undefined;
      if (this.token) {
        this.sessionApiService.getSessionByRecoveryToken(this.token).subscribe(result => {
          if (result && result.id) {
            this.user = result;
            this.resetPasswordForm.controls['id'].setValue(result.id);
          } else {
            this.snackBar.showSimpleClose(CommonErrors.RecoveryTokenExpired.code, SnackBarRole.warning);
            this.router.navigate(['login']);
          }
        }, err => {
          this.snackBar.showSimpleClose(err.code, SnackBarRole.warning);
          this.router.navigate(['login']);
        });
      } else {
        this.router.navigate(['login']);
      }
    });
  }

  async submit() {
    this.disabled = true;
    try {
      this.user.password = this.resetPasswordForm.controls['password'].value;
      this.userService.persist(this.user).subscribe(user => {
        if (user) {
          this.router.navigate(['/home']);
        }
      });
    } catch (e) {
      this.snackBar.showSimpleClose('different-passwords', SnackBarRole.error);
    } finally {
      this.disabled = false;
    }
  }
}
