import { AbstractControl } from '@angular/forms';

export class ConfirmPasswordValidator {
  static MatchPassword(control: AbstractControl) {
    const password = control.get('password').value;
    const passwordConfirm = control.get('passwordConfirm').value;
    if ((!!password || !!passwordConfirm) && password !== passwordConfirm) {
      control.get('passwordConfirm').setErrors( {ConfirmPassword: true} );
    } else {
      control.get('passwordConfirm').setErrors(null);
    }
  }
}
