<div class="login-container">
  <mat-card>
    <div class="login-spacer"></div>
    <div class="login-container">
      <div id="login-fab" class="mdl-color--accent mdl-color-text--white">
        <i id="lock-icon" class="material-icons">lock</i>
      </div>
      <h2 class="card-heading">
        <img src="../../../assets/icons/ivaj-logo.svg" />
      </h2>
      <form class="login-form" [formGroup]="loginForm" (submit)="submit()">
        <h2 translate>{{ isRecovery ? 'password-recovery' : 'connect' }}</h2>
        <mat-form-field>
          <mat-label translate>{{ isRecovery ? 'email' : 'enter-email-or-user' }}</mat-label>
          <input
            matInput
            type="email"
            formControlName="email"
          />

          <mat-error *ngIf="loginForm.invalid && (loginForm.dirty || loginForm.touched)">
            <div *ngIf="loginForm.controls['email'].hasError('required')" translate>
              required-field
            </div>
            <div *ngIf="loginForm.controls['email'].hasError('pattern') && !isRecovery" translate>
              verify-email-nick
            </div>
            <div *ngIf="loginForm.controls['email'].hasError('pattern') && isRecovery" translate>
              verify-email
            </div>
          </mat-error>
        </mat-form-field>
        <br />
        <ng-container *ngIf="!isRecovery">
          <mat-form-field>
            <mat-label translate>password</mat-label>

            <input
              matInput
              (keydown.enter)="submit()"
              type="password"
              formControlName="password"
            />

            <mat-error *ngIf="loginForm.invalid && (loginForm.dirty || loginForm.touched)">
              <div *ngIf="loginForm.controls['password'].hasError('required')" translate>
                required-field
              </div>
              <div *ngIf="loginForm.controls['password'].hasError('minlength')" translate>
                error-min-length-pass
              </div>
            </mat-error>
          </mat-form-field>
        </ng-container>
      </form>
      <div class="buttons">
        <div class="login">
          <button
            mat-raised-button
            type="submit"
            color="primary"
            [disabled]="
              isRecovery ? loginForm.controls['email'].hasError('required') : loginForm.invalid
            "
            (click)="submit()"
            translate
          >
            {{ isRecovery ? 'send' : 'login' }}
          </button>
        </div>
        <!-- <div class="recovery">
          <button
            mat-raised-button
            class="to-end"
            color="primary"
            (click)="toggleRecovery()"
            translate
          >
            {{ isRecovery ? 'back-to-login' : 'password-recovery' }}
          </button>
        </div> -->
      </div>
    </div>
  </mat-card>
</div>

<!-- <div class="main-page" align="center">
  <form class="flex-container" novalidate="novalidate" (ngSubmit)="submit()" fxLayout="column" [formGroup]="loginForm">
    <md-card class="container high-elevation">
      <md-card-title class="element circle" color="primary">
        <div class="circle"></div>
      </md-card-title>
      <md-card-content>
        <div class="element" fxLayout="row" fxFlex="fxFlex">
          <md-icon>perm_identity</md-icon>
          <md-input-container fxFlex="fxFlex"><input mdInput="mdInput" type="email" placeholder="{{ &quot;Email&quot; }}"
              formControlName="email" />
            <md-hint *ngIf="loginForm.controls.email?.errors?.required">{{ 'email_required' }}</md-hint>
          </md-input-container>
        </div>
        <div class="element last" fxLayout="row" fxFlex="fxFlex">
          <md-icon>lock_outline</md-icon>
          <md-input-container fxFlex="fxFlex"><input mdInput="mdInput" type="password" placeholder="{{ &quot;Password&quot; }}"
              formControlName="password" fxFlex="fxFlex" />
            <md-hint *ngIf="loginForm.controls.password?.errors?.required">{{ 'password_required' }}</md-hint>
          </md-input-container>
        </div>
        <div align="end"><button md-raised-button="md-raised-button" md-color="accent" type="submit" [disabled]="loginForm.invalid">{{
            'enter' }}</button></div>
      </md-card-content>
    </md-card>
  </form>
  <a md-button="md-button" routerLink="/card/register">{{ 'create_new_account'}}</a>
</div> -->
