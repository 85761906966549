import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { SessionRoutingModule } from './session.routing';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LocalizationModule } from 'angular-l10n';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [
    LoginComponent,
    ResetPasswordComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    SessionRoutingModule,
    LocalizationModule
  ],
  providers: []
})
export class SessionModule  {
}
