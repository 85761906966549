import { Component } from '@angular/core';
import { Input } from '@angular/core';
import { SessionService } from '../shared/session/session.service';

@Component({
  selector: 'wb-app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  @Input()
  public showMenu: boolean;
  public opened = false;

  public menuModules: IMenuItem[] = [
    // { name: 'home', routerLink: ['/home'] },
    // { name: 'users', routerLink: ['/user/list'], admins: true },
    { name: 'activities', routerLink: ['/activity/list'], admins: true },
    // { name: 'signup', routerLink: ['/user/register'], session: 'without' },
    // {
    //   name: 'calabazas',
    //   routerLink: ['/users'],
    //   session: 'with',
    //   admins: false // logged in users menu item
    // }
  ];

  constructor(
    public sessionService: SessionService
  ) { }

  close(): void {
    this.opened = false;
  }

  logout(): void {
    this.close();
    this.sessionService.logout(true);
  }

  get menuOptions(): IMenuItem[] {
    let options = this.menuModules;
    const isAdmin = this.sessionService.isAdmin();
    if (this.sessionService.isLoggedIn()) {
      options = options.filter(item => {
        return (!item.session && !item.admins) || (item.session === 'with' && !item.admins) || (!!item.admins && isAdmin);
      });
    } else {
      options = options.filter(item => (!item.session || item.session === 'without') && !item.admins);
    }
    return options;
  }

  open(): void {
    this.opened = true;
  }
}

export interface IMenuItem {
  name: string;
  shortName?: string;
  routerLink: any[];
  session?: 'with' | 'without';
  admins?: boolean;
}
