import { ApiTransformable } from '../api-transformable';
import { IApiUser, IUserRole } from '../../../common/interfaces/user.api.interface';

export class User implements ApiTransformable<IApiUser>, IApiUser {
  id?: number;
  createdAt?: Date;
  nick: string;
  name: string;
  surname: string;
  email: string;
  phone: string;
  password: string;
  roles: IUserRole[];

  constructor(that: IApiUser) {
    this.id = that.id;
    this.createdAt = that.createdAt;
    this.nick = that.nick;
    this.name = that.name;
    this.surname = that.surname;
    this.email = that.email;
    this.phone = that.phone;
    this.password = that.password;
    this.roles = that.roles;
  }

  getCompleteName(): string {
    return `${this.name} ${this.surname}`;
  }

  toApi(): IApiUser {
    return {
      id: this.id,
      createdAt: this.createdAt,
      nick: this.nick,
      name: this.name,
      surname: this.surname,
      email: this.email,
      phone: this.phone,
      password: this.password,
      roles: this.roles
    } as IApiUser;
  }
}
