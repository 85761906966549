export interface ErrorType {
  code: string;
  status?: number;
  description?: string;
}

export const CommonErrors = {
  DupEntry: {
    code: 'ER_DUP_ENTRY',
    status: 500,
    description: 'Tried to create an entity which unique field already exists.'
  },
  ElementCreation: {code: 'ERROR_CREATING_ELEMENT'},
  ElementDelete: {code: 'ERROR_DELETING_ELEMENT'},
  ElementNotFound: {code: 'ERROR_ELEMENT_NOT_FOUND'},
  ElementUpdate: {code: 'ERROR_UPDATING_ELEMENT'},
  ExceededQuota: {code: 'ERROR_EXCEEDED_QUOTA', status: 403},
  FileNotFound: {code: 'ERROR_FILE_NOT_FOUND'},
  Forbidden: {code: 'ERROR_FORBIDDEN', status: 403},
  Generic: {code: 'ERROR_GENERIC'},
  InfiniteRecursion: {code: 'ERROR_INFINITE_RECURSION'},
  InputRequired: {code: 'ERROR_INPUT_REQUIRED'},
  InvalidCredentials: {code: 'ERROR_INVALID_CREDENTIALS', status: 403},
  InvalidForm: {code: 'ERROR_INVALID_FORM', description: 'Form is not valid.'},
  JwtError: {code: 'ERROR_JWT', status: 401},
  JwtExpired: {code: 'ERROR_JWT_EXPIRED', status: 401},
  MailingNotEnabled: {code: 'ERROR_MAILING_NOT_ENABLED'},
  MethodNotImplemented: {code: 'ERROR_METHOD_NOT_IMPLEMENTED'},
  RecoveryTokenExpired: {code: 'ERROR_RECOVERY_TOKEN_EXPIRED'},
  SessionExpired: {code: 'ERROR_SESSION_EXPIRED', status: 401},
  SessionNotFound: {code: 'ERROR_SESSION_NOT_FOUND'},
  SqlWrongInput: {code: 'ERROR_SQL_WRONG_INPUT'},
  UnknownError: {code: 'ERROR_UNKNOWN', description: 'The error could not be recognised.'},
  UnreachableServer: {code: 'ERROR_UNREACHABLE_SERVER', status: 504, description: 'Could not connect with server.'},
  UserCreation: {code: 'ERROR_USER_CREATION'},
  UserNotFound: {code: 'ERROR_USER_NOT_FOUND', status: 401},
  UserNotLogged: {code: 'ERROR_USER_NOT_LOGGED', status: 401}
};
