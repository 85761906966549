/**
 * Created by sergio on 14/10/2016.
 */
import { Injectable } from '@angular/core';
import { SnackBarComponent } from './snackbar.component';
import { SnackBarRole } from './snackbar-role.enum';
import { SnackBarCommService } from './snackbar-comm.service';
import { ISnackBar } from './snackbar.interface';
import { TranslationService } from 'angular-l10n';
import { MatSnackBar, MatSnackBarRef, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable()
export class SnackBarService {


  constructor(
    private snackBar: MatSnackBar,
    public comm: SnackBarCommService<SnackBarComponent>,
    public translation: TranslationService) {
  }

  showSimpleClose(message: string,
    role: SnackBarRole = SnackBarRole.error,
    params?: Object,
    durationMillis?: number): MatSnackBarRef<any> {
    const config: MatSnackBarConfig = {
      // announcementMessage: this.translation.translate(message, params),
      duration: durationMillis || 1000000
    };
    const ref = this.snackBar.openFromComponent(SnackBarComponent, config);
    const snack: ISnackBar<SnackBarComponent> = {
      // message: 'message',
      // actionLabel: 'close',
      message: this.translation.translate(message, params),
      actionLabel: this.translation.translate('close'),
      role: role,
      snackBarRef: ref
    };
    this.comm.addSnack(snack);
    return ref;
  }
}
