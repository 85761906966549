import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { IConfirmDialogData } from '../dialog.interface';

@Component({
  selector: 'wb-confirm-dialog',
  templateUrl: 'confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<IConfirmDialogData>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: IConfirmDialogData) {}
}
