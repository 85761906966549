import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import * as moment from 'moment';
import { AppRoutingModule } from './app.routing';
import { LocaleService, L10nLoader, L10nConfig, StorageStrategy, ProviderType, LocalizationModule } from 'angular-l10n';
import { MenuComponent } from './menu/menu.component';
import { SharedModule } from './shared/shared.module';
import { SessionModule } from './+session/session.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './interceptors/httpconfig.interceptor';
import { HomeComponent } from './home/home.component';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { ServiceWorkerModule, SwUpdate, UpdateAvailableEvent } from '@angular/service-worker';
import { environment } from 'src/environments/environment';

moment.locale('es');

export const l10nConfig: L10nConfig = {
  locale: {
    languages: [
      { code: 'es', dir: 'ltr' },
      { code: 'en', dir: 'ltr' }
    ],
    language: 'es',
    // Only works if browser lang is not defined
    defaultLocale: { languageCode: 'es', countryCode: 'ES' },
    currency: 'EUR',
    storage: StorageStrategy.Cookie,
  },
  translation: {
    providers: [
      { type: ProviderType.Static, prefix: './assets/l10n/locale-' }
    ],
    caching: true,
    // missingValue: 'Missing translation key'
  }
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MenuComponent,
  ],
  imports: [
    LocalizationModule.forRoot(l10nConfig),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatToolbarModule,
    SessionModule,
    HttpClientModule,
    SharedModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),

  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(locale: LocaleService, public l10nLoader: L10nLoader, public matIconReg: MatIconRegistry, private swUpdate: SwUpdate) {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe((event: UpdateAvailableEvent) => {
        if (confirm('Hay una nueva versión. Confirme para actualizar.')) {
          window.location.reload();
        }
      });
    }
    locale.setCurrentLanguage('es');
    this.l10nLoader.load();
  }
}


