<div class="reset-password-container">
  <mat-card>
    <div class="reset-password-spacer"></div>
    <div class="reset-password-container">
      <h2 class="card-heading">
        <img src="../../../assets/images/logo.png">
      </h2>
      <form class="reset-password-form" [formGroup]="resetPasswordForm" (submit)="submit()">
        <h2 translate>password-recovery</h2>
        <mat-form-field>
          <input matInput (keydown.enter)="submit()" type="password" formControlName="password" placeholder="{{ 'password' | translate:lang }}">
          <mat-error *ngIf="resetPasswordForm.invalid && (resetPasswordForm.dirty || resetPasswordForm.touched)">
            <div *ngIf="resetPasswordForm.controls['password'].hasError('required')" translate>
              required-field
            </div>
            <div *ngIf="resetPasswordForm.controls['password'].hasError('minlength')" translate>
              error-min-length-pass
            </div>
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput (keydown.enter)="submit()" type="password" formControlName="passwordConfirm" placeholder="{{ 'confirm-password' | translate:lang }}">
          <mat-error *ngIf="resetPasswordForm.invalid && (resetPasswordForm.dirty || resetPasswordForm.touched)">
            <div *ngIf="resetPasswordForm.controls['passwordConfirm'].hasError('required')" translate>
              required-field
            </div>
            <div translate>
              different-passwords
            </div>
          </mat-error>
        </mat-form-field>
      </form>
      <div class="buttons">
        <div class="change-password">
          <button mat-raised-button type="submit" color="primary" (click)="submit()" [disabled]="resetPasswordForm.invalid" translate>change-password</button>
        </div>
      </div>
    </div>
  </mat-card>
</div>



<!-- <div class="main-page" align="center">
  <form class="flex-container" novalidate="novalidate" (ngSubmit)="submit()" fxLayout="column" [formGroup]="resetPasswordForm">
    <md-card class="container high-elevation">
      <md-card-title class="element circle" color="primary">
        <div class="circle"></div>
      </md-card-title>
      <md-card-content>
        <div class="element" fxLayout="row" fxFlex="fxFlex">
          <md-icon>perm_identity</md-icon>
          <md-input-container fxFlex="fxFlex"><input mdInput="mdInput" type="email" placeholder="{{ &quot;Email&quot; }}"
              formControlName="email" />
            <md-hint *ngIf="resetPasswordForm.controls.email?.errors?.required">{{ 'email_required' }}</md-hint>
          </md-input-container>
        </div>
        <div class="element last" fxLayout="row" fxFlex="fxFlex">
          <md-icon>lock_outline</md-icon>
          <md-input-container fxFlex="fxFlex"><input mdInput="mdInput" type="password" placeholder="{{ &quot;Password&quot; }}"
              formControlName="password" fxFlex="fxFlex" />
            <md-hint *ngIf="resetPasswordForm.controls.password?.errors?.required">{{ 'password_required' }}</md-hint>
          </md-input-container>
        </div>
        <div align="end"><button md-raised-button="md-raised-button" md-color="accent" type="submit" [disabled]="resetPasswordForm.invalid">{{
            'enter' }}</button></div>
      </md-card-content>
    </md-card>
  </form>
  <a md-button="md-button" routerLink="/card/register">{{ 'create_new_account'}}</a>
</div> -->
