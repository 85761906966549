import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { Router } from '@angular/router';
import { find } from 'lodash';
import { IUserRole, UserRole } from '../../../common/interfaces/user.api.interface';

@Injectable()
export class SessionService {
  private _user: User;
  public get user() {
    return this._user;
  }

  constructor(
    private router: Router
  ) {
    try {
      this._user = new User(JSON.parse(localStorage.getItem('user')));
    } catch (err) {
      this._user = null;
    }
  }

  signIn(user: User) {
    this._user = user;
    try {
      localStorage.setItem('user', JSON.stringify(user.toApi()));
    } catch (err) {}
  }

  isLoggedIn(): boolean {
    return !!this._user;
  }

  isAdmin(): boolean {
    return (
      this.isLoggedIn() &&
      !!find(this.user.roles, (role: IUserRole) => role.id === UserRole.APP_ADMIN)
    );
  }

  logout(toLogin?: boolean) {
    this._user = null;
    try {
      localStorage.removeItem('user');
    } catch (err) {
      console.error(err);
    }
    this.router.navigate([toLogin ? '/session/login' : '/']);
  }
}
