import { HomeComponent } from './home/home.component';
import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const appRoutes: Routes = [
  { path: '', redirectTo: 'activity', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  {
    path: 'session',
    loadChildren: () => import('./+session/session.module').then((m) => m.SessionModule),
  },
  { path: 'user', loadChildren: () => import('./+user/user.module').then((m) => m.UserModule) },
  {
    path: 'activity',
    loadChildren: () => import('./+activity/activity.module').then((m) => m.ActivityModule),
  },
];

export const AppRoutingProviders: any[] = [];

export const AppRoutingModule: ModuleWithProviders<RouterModule> = RouterModule.forRoot(appRoutes);
