<div class="cont-confirm-dialog">
  <h2 mat-dialog-title *ngIf="data.title">{{data.title}}</h2>
  <div class="cont-image">
    <img class="icon-delete" src="../../../../assets/images/icon-delete.png">
  </div>
  <mat-dialog-content class="message-delete">{{data.question}}</mat-dialog-content>
  <div class="message-buttons">
    <button class="sanos-button sanos-button-primary" color="warn" [mat-dialog-close]="true">Aceptar</button>
    <button class="sanos-button sanos-button-secondary" [mat-dialog-close]="false">Cancelar</button>
  </div>
</div>
