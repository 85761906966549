import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SnackBarComponent } from './snackbar/snackbar.component';
import { SnackBarCommService } from './snackbar/snackbar-comm.service';
import { SessionService } from './session/session.service';
import { SnackBarService } from './snackbar/snackbar.service';
import { UserApiService } from './services/user.api.service';
import { UtilsService } from './utils.service';
import { LoggedInGuard } from './logged-in.guard';
import { HttpClientModule } from '@angular/common/http';
import { CustomIconService } from './services/custom-icon.service';
import { SessionApiService } from './session/session.api.service';
import { RoleApiService } from './services/role.api.service';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    MatButtonModule,
    MatDialogModule,
    MatOptionModule,
    MatSelectModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  declarations: [
    ConfirmDialogComponent,
    SnackBarComponent,
  ],
  entryComponents: [
    ConfirmDialogComponent,
    SnackBarComponent
  ],
  exports: [
    ConfirmDialogComponent,
    SnackBarComponent,
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        CustomIconService,
        LoggedInGuard,
        RoleApiService,
        SessionService,
        SessionApiService,
        SnackBarCommService,
        SnackBarService,
        UserApiService,
        UtilsService,
      ]
    };
  }
}
