import { Injectable } from '@angular/core';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { DatePipe } from '@angular/common';
import { FormGroup, FormControl } from '@angular/forms';
import { TranslationService } from 'angular-l10n';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class UtilsService {

  public cifPattern = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
  public DNI_REGEX = /^(\d{8})([A-Z])$/;
  public NIE_REGEX = '/^[XYZ]\d{7,8}[A-Z]$/';
  public emailOrNickPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$|^[a-zA-Z0-9_-]{5,15}$/;
  public emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  public unamePattern = '^[a-zA-Z0-9_-]{5,15}$';

  public phonePattern = /^(\+[\d]{1,5}|0)?[0-9]\d{7,15}$/;
  constructor(private dialog: MatDialog, public translation: TranslationService) {
  }

  backgroundImage(img: string, defaultAvatarImage?: boolean) {
    if (!img && defaultAvatarImage) {
      img = '/assets/images/avatar-small.svg';
    }
    return { 'background-image': `url('${img}')` };
  }

  compareById(a: any, b: any) { // We do not have a BaseEntity to use it instead of "any"
    return a.id === b.id;
  }

  confirmDialog(question: string, title: string = null): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const dialogRef = this.dialog.open(ConfirmDialogComponent,
        {
          data: {
            title: title,
            question: this.translation.translate(question)
          }
        });

      dialogRef.afterClosed().subscribe(result => {
        resolve(result);
      });
    });
  }

  getHour(d: Date) {
    return new DatePipe('en-GB').transform(d, 'HH:mm');
  }

  getInitials(fullName: string) {
    let result = '';
    if (fullName) {
      const words = fullName.toUpperCase().split(' ');
      let i = 0;
      while (i < 2 && i < words.length) {
        result += words[i++].charAt(0);
      }
    }
    return result;
  }

  toLowerCase (value: string): string {
    return value ? value.toLowerCase() : null;
  }

  validateForm(form: FormGroup) {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
      }
    });
  }
}
