import { Component, OnInit, OnDestroy } from '@angular/core';
import { SnackBarCommService } from './snackbar-comm.service';
import { SnackBarRole } from './snackbar-role.enum';
import { Subscription } from 'rxjs';
import { isNil } from 'lodash-es';
import { ISnackBar } from './snackbar.interface';
import { Translation, TranslationService } from 'angular-l10n';

@Component({
  selector: 'wb-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackBarComponent implements OnInit, OnDestroy {

  public content: ISnackBar<SnackBarComponent>;

  private contentSubscription: Subscription;

  constructor(private comm: SnackBarCommService<SnackBarComponent>,
    public translation: TranslationService) {
    this.contentSubscription = comm.content$.subscribe(content => this.content = content);
  }

  public ngOnInit() {
  }

  public ngOnDestroy() {
    if (!isNil(this.contentSubscription)) {
      this.contentSubscription.unsubscribe();
    }
  }

  public getRoleClass(role: SnackBarRole): string {
    return SnackBarRole[role];
  }

  public action(): void {
    if (!isNil(this.content.action)) {
      this.content.action();
    }
    this.content.snackBarRef.dismiss(); // Always dismiss on action?
  }

}
