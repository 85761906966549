import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { TranslationService } from 'angular-l10n';
import { SessionService } from './shared/session/session.service';
import { Router } from '@angular/router';
import { CustomIconService } from './shared/services/custom-icon.service';

@Component({
  selector: 'wb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedLanguage: string;

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    private customIconService: CustomIconService,
    private router: Router,
    private session: SessionService,
    public translation: TranslationService,
    ) {
      customIconService.init();
  }

  ngOnInit() {  }

  showMenu(): boolean {
    return true;
    // return this.session.isLoggedIn() &&
    //   (this.router.url !== '/session/login') &&
    //   (!this.router.url.startsWith('/session/reset-password')) &&
    //   (!this.router.url.startsWith('/user/register'));
  }
}
