/**
 * Created by sergio on 15/12/2016.
 */
import { Injectable } from '@angular/core';
import { ISnackBar } from './snackbar.interface';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable()
export class SnackBarCommService<T> {
  private contentSource: Subject<ISnackBar<T>> = new Subject<ISnackBar<T>>();
  public content$: Observable<ISnackBar<T>>    = this.contentSource.asObservable();

  constructor() { }

  addSnack(snack: ISnackBar<T>) {
    this.contentSource.next(snack);
  }
}
