import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from '../../../environments/environment';
import { IApiUser } from '../../../common/interfaces/user.api.interface';
import { map as _map } from 'lodash-es';

@Injectable()
export class UserApiService {

  private apiUrl = `${environment.api}/user`;

  constructor(
    private httpClient: HttpClient
  ) { }

  getAll(): Observable<User[]> {
    return this.httpClient.get<IApiUser[]>(`${this.apiUrl}/`)
      .pipe(map(apiUsers => _map(apiUsers, apiUser => {
        return new User(apiUser);
      })));
  }

  getById(userId: number): Observable<User> {
    return this.httpClient.get<IApiUser>(`${this.apiUrl}/${userId}`)
      .pipe(map(user => new User(user)));
  }

  /**
   * Creates or updates an Entity (User)
   * @param user Complete or partial user to create or update
   */
  persist(user: User): Observable<User> {
    let obs: Observable<IApiUser>;
    if (user.id) {
      obs = this.httpClient.put<IApiUser>(`${this.apiUrl}/${user.id}`, user.toApi());
    } else {
      obs = this.httpClient.post<IApiUser>(`${this.apiUrl}/`, user.toApi());
    }
    return obs.pipe(map(res => new User(res)));
  }

  delete(userId: number): Observable<{ deleted: boolean }> {
    return this.httpClient.delete(`${this.apiUrl}/${userId}`)
      .pipe(map(() => ({ deleted: true })));
  }
}
