import { IUserRole } from '../../../common/interfaces/user.api.interface';
import { ApiTransformable } from '../api-transformable';

export class Role implements ApiTransformable<IUserRole>, IUserRole {
  id: number;
  name: string;

  constructor(that: IUserRole) {
    this.id = that.id;
    this.name = that.name;
  }

  toApi(): IUserRole {
    return <IUserRole>{
      id: this.id,
      name: this.name
    };
  }

}
