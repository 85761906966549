import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SessionService } from './session/session.service';

@Injectable()
export class LoggedInGuard implements CanActivate {
  constructor(private sessionServce: SessionService,
  private router: Router) { }

  canActivate() {
     if (this.sessionServce.isLoggedIn()) {
       return true;
     } else {
       this.router.navigate(['/login']);
       return false;
     }
  }
}
