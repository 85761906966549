<mat-toolbar color="primary">
  <button mat-button id="showMenu" *ngIf="showMenu" (click)="open()">
    <mat-icon svgIcon="open-menu"></mat-icon>
  </button>
  <span>Ivaj   Xarxa Jove</span>
</mat-toolbar>
<mat-sidenav-container id="mainMenu" (backdropClick)="close()">
  <mat-sidenav mode="overlay" [opened]="opened" *ngIf="showMenu" (keydown.escape)="close()">
    <div class="menu-groups-container">
      <div class="column">
        <button
          mat-button
          *ngFor="let menuItem of menuOptions"
          [routerLink]="menuItem.routerLink"
          (click)="close()"
          translate
        >
          {{ menuItem.name }}
        </button>
        <button
          mat-button
          (click)="close()"
          [routerLink]="'session/login'"
          *ngIf="!sessionService.isLoggedIn()"
          translate
        >
          login
        </button>
      </div>
      <div class="column">
        <button mat-button (click)="logout()" *ngIf="sessionService.isLoggedIn()" translate>
          logout
        </button>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
