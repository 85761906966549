import { IApiEntity } from './entity.api.interface';

export interface IApiUser extends IApiEntity {
  nick?: string;
  name?: string;
  surname?: string;
  email?: string;
  phone?: string;
  password?: string;
  picture?: string;
  isActive?: boolean;
  roles?: IUserRole[];
  createdAt?: Date;
  updatedAt?: Date;
}

export interface IUserRole {
  id: UserRole;
  name: string;
}

export enum UserRole {
  GUEST = 0,
  BASIC_USER = 1,
  APP_ADMIN = 99
}
