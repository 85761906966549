import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { SessionApiService } from '../../shared/session/session.api.service';
import { Router, ActivatedRoute } from '@angular/router';
import last from 'lodash-es/last';
import { SnackBarService } from '../../shared/snackbar/snackbar.service';
import { SnackBarRole } from '../../shared/snackbar/snackbar-role.enum';
import { Language } from 'angular-l10n';
import { SessionService } from '../../shared/session/session.service';
import { UtilsService } from '../../shared/utils.service';

@Component({
  selector: 'wb-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @Language()
  public lang: string;

  public loginForm: FormGroup;
  public isRecovery: boolean;
  public disabled: boolean;

  public submitted = false;

  constructor( public sessionApiService: SessionApiService,
     public router: Router,
     public route: ActivatedRoute,
     private formBuilder: FormBuilder,
     private sessionService: SessionService,
     private utils: UtilsService,
     private snackBar: SnackBarService) {}

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(this.utils.emailOrNickPattern)]],
      password: ['', [Validators.required]]
    });

    this.disabled = false;
    this.isRecovery = this.route.snapshot.url[0].path === 'recovery';

    if (last(this.route.snapshot.url).path.includes('logout')) {
      this.sessionApiService.logout().subscribe(() => { });
    }
    if (this.sessionService.isLoggedIn()) {
      this.router.navigate(['/activity/list']);
    }
  }

  async submit() {
    if (!this.isRecovery) {
      if (!this.loginForm.invalid) {
        this.disabled = true;
        try {
          await this.sessionApiService.login(this.loginForm.value).toPromise();
          this.router.navigate(['/activity/list']);
        } catch (e) {
          this.snackBar.showSimpleClose(e.code, SnackBarRole.error);
        } finally {
          this.disabled = false;
        }
      }
    } else {
      this.disabled = true;
      try {
        await this.sessionApiService.recovery(this.loginForm.controls.email.value).toPromise();
        this.snackBar.showSimpleClose('recovery-email-sent', SnackBarRole.info);
        this.router.navigate(['/']);
      } catch (e) {
        this.snackBar.showSimpleClose(e.code, SnackBarRole.error);
      } finally {
        this.disabled = false;
      }
    }
  }

  toggleRecovery() {
    this.loginForm.controls['email'].clearValidators();
    const validators = this.isRecovery ?
      [Validators.required, Validators.pattern(this.utils.emailOrNickPattern)] :
      [Validators.required, Validators.pattern(this.utils.emailPattern)];
    this.loginForm.controls['email'].setValidators(validators);
    this.loginForm.controls['email'].setValue(this.loginForm.controls['email'].value);
    this.isRecovery = !this.isRecovery;
  }
}
