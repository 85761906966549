import { ErrorType } from './errors';

export class ClientError extends Error {

  public code: string;
  public status: number;

  constructor(error: ErrorType) {
    super(error.description);
    this.code = error.code;
    this.status = error.status;
    console.error(`${!this.status ? 'Client' : 'Server'} error captured: `, error);
  }

}
