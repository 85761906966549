import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { throwError, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { SessionService } from '../shared/session/session.service';
import { ClientError } from '../../common/client-error';
import { CommonErrors, ErrorType } from '../../common/errors';
import { find } from 'lodash-es';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  public loginRoute = 'session/login';
  public refreshRoute = `/api/session/refreshToken`;

  constructor(
    private session: SessionService,
  ) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(catchError(event => {
        if (event instanceof HttpErrorResponse) {
          if (event.status === 401) {
            console.log('401 found, trying refresh token');
            const refReq = request.clone({ method: 'POST', body: null, url: this.refreshRoute, withCredentials: true });
            // const refReq = new HttpRequest('POST', this.refreshRoute);
            refReq.headers.append('Content-Type', 'application/json;charset=utf-8');
            return next.handle(refReq);
          }
          if (event.status === 504) {
            return throwError(new ClientError(CommonErrors.UnreachableServer).code);
          }
          if (event.status > 401) {
            const e: ErrorType = !!event.error.data ?
              find(CommonErrors, er => er.code === event.error.data.code) : CommonErrors.UnknownError;
            return throwError(new ClientError(!!e ? e : CommonErrors.UnknownError).code);
          }
        }
        return throwError(event);
      }))
      .pipe(catchError(refreshError => {
        if (refreshError instanceof HttpErrorResponse && refreshError.url.includes(this.refreshRoute)) {
          this.session.logout(true);
          // window.location.href = this.loginRoute;
          return of(refreshError);
        }
        return throwError(refreshError);
      }))
      .pipe(mergeMap(event => {
        if (event instanceof HttpResponse && event.status === 200 && event.url.includes(this.refreshRoute)) {
          return next.handle(request);
        }
        return of(event);
      }))
      .pipe(map((res: any) => {
        if (res instanceof HttpResponse && !!res.url && res.url.indexOf(environment.api) >= 0 && res.body.data) {
          res = res.clone({ body: res.body.data });
          res.body = res.body ? res.body.data ? res.body.data.data ? res.body.data.data : res.body.data : res.body : null;
          return res;
        }
        return res;
      }));
  }
}
